<template>
  <div>
    <top-decorations></top-decorations>
    <div class="intro-small">
        <div class="container">
        <h1 class="page-title">Ongoing Project</h1>
        <div class="page-description">
          <h6 class="green">{{currentEdition}}' edition</h6>
          <h1 class="light pt-0">{{currentAcademicYear}}</h1>
        <p> Student’s final projects represent the ultimate delivery of the master program. They are also the brightest representation of the program itself.  They combine advanced computing technologies with scientific and industrial frontier applications.</p>
    </div>
    </div>
    </div>
    <projects-list :showOngoing="true"></projects-list>
  </div>
</template>
<script>
import TopDecorations from "@/components/TopDecorations.vue";
import ProjectsList from "@/components/ProjectsList.vue";
export default {
    name:"ongoing-project",
  components: { TopDecorations, ProjectsList },
  data() {
    return {};
  },
  computed:{
    currentAcademicYear(){
      return this.$store.state.currentAcademicYear
    },
    currentEdition(){
      const firstYear = 2014;
      return (parseInt(this.$store.state.currentAcademicYear.split('/')[0]) + 1) - firstYear
    }
  },
  methods: {},
};
</script>
